import { api, param2query } from "./api.service";

export const getAll = (params, cancelToken) => {
  return api.get('hospital/listar?' + param2query(params), cancelToken);
};

export const getHospital = (id) => {
  return api.get('hospital/' + id);
};

export const criarHospital = (model) => {
  return api.post('hospital', model)
}

export const atualizarHospital = (model) => {
  return api.put('hospital', model)
}

export const getAllUsuarios = (id) => {
  return api.get('hospital/listar-usuarios/' + id);
};

export const pesquisarUsuarioSelect = (query) => {
  return api.get('hospital/buscar-usuarios?filtro=' + query);
}

export const adicionarUsuarioHospital = (usuarioId, hospitalId) => {
  return api.post('hospital/adicionar-usuario', {
    usuarioId, hospitalId,
    ativo: true, isCriar: true
  })
}

export const alterarUsuarioHospital = (usuarioId, hospitalId, ativo) => {
  return api.put('hospital/alterar-usuario', {
    usuarioId, hospitalId, ativo, isCriar: false}
  )
}

export const excluirUsuarioHospital = (usuarioId, hospitalId) => {
  return api.delete('hospital/excluir-usuario?' + param2query({ usuarioId, hospitalId}) 
  )
}

export const getAllConvenio = (params, cancelToken) => {
  return api.get('hospital/buscar-convenios?' + param2query(params), cancelToken);
};

export const getAllEstado = (params, cancelToken) => {
  return api.get('hospital/listar-estados?' + param2query(params), cancelToken);
};

export default { getAll };
